import React from 'react';
import block from 'bem-cn-lite';
import { Button, Icon, Label, TextInput, useToaster } from '@gravity-ui/uikit';

import './Qr.scss';
import {Layout} from '../../components/layout';
import { InputLabel } from '../../components/input-label';
import {ReactComponent as QrCountBtnIcon} from '../../assets/qr-count.svg';
import { Box, Stack } from '@mui/material';
import { useGenerateTagMutation } from '../../store/api/admin';
import { isDigitOrEmpty } from '../../utils/validation';
import { CircleCheckFill, CircleXmarkFill } from '@gravity-ui/icons';
import { ISticker, StickerCarousel, stickers, StickerType } from './ui';
import { ImgWrapper } from '../../components/img-wrapper';

const b = block('qr-page');

const qrCountVariants = [10, 50, 100, 200];

export const QrPage: React.FC = () => {
  const {add} = useToaster();
  const [generateTag, generateTagRequestInfo] = useGenerateTagMutation();

  const [currentSticker, setCurrentSticker] = React.useState<ISticker | null | undefined>(stickers.filter(s => s.type === StickerType.rectangle)[0]); 
  const [stickerType, setStickerType] = React.useState<string | StickerType>(StickerType.rectangle);
  const handleOnStickerTypeChange = (type: StickerType | string) => {
    setStickerType(type);
    setCurrentSticker(stickers.filter(s => s.type === type)[0]);
  };
  const stickersByType = React.useMemo(() => stickers.filter(s => s.type === stickerType), [stickerType]);
  
  const [disableGenBtn, setDisableGenBtn] = React.useState(true);
  const [description, setDescription] = React.useState("");
  const [count, setCount] = React.useState("");

  const handleOnDescriptionChange = (e: any) => setDescription(e.target.value);
  const handleOnCountChange = (e: any) => {
    if (isDigitOrEmpty(e.target.value)) {
      setCount(e.target.value)
    }
  }

  const handleOnCountLabelClick = (count: number) => setCount(count.toString());

  const handleGenerateBtnClick = () => {
    if (currentSticker) {
      generateTag({
        count: parseInt(count),
        description,
        designType: currentSticker.key
      })
    }
  }

  React.useEffect(() => {
    if (count && description && currentSticker) {
      setDisableGenBtn(false);
    } else {
      setDisableGenBtn(true);
    }
  }, [count, description])

  React.useEffect(() => {
    if (generateTagRequestInfo.isSuccess) {
      add({
        name: 'generate-tag-success',
        autoHiding: 3000,
        isClosable: true,
        title: `Сгенерировано ${count} QR-кодов`,
        renderIcon: () => <Icon className="success-green" data={CircleCheckFill} size={18} />,
      });
      setCount("");
      setDescription("");
    }
  }, [generateTagRequestInfo.isSuccess])

  React.useEffect(() => {
    if (generateTagRequestInfo.isError) {
      add({
        name: 'generate-tag-success',
        autoHiding: 3000,
        isClosable: true,
        title: `Ошибка в запросе`,
        renderIcon: () => <Icon className="success-red" data={CircleXmarkFill} size={18} />,
        // @ts-ignore
        content: generateTagRequestInfo.error.error.message,
      });
    }
  }, [generateTagRequestInfo.isError, generateTagRequestInfo.error])


  return (
    <Layout>
      <div className={b()}>

        <Stack
          p={4}
          spacing={1}
          borderRadius={6}
          width='100%'
          height='100%'
          direction='row'
          bgcolor='#0000000D'
        >
          <div className={b('qr-form')}>
            <h3 className='fs48-primary-bold'>
              Генерация QR-кодов
            </h3>
            <InputLabel labelText='Описание'>
              <TextInput 
                size='xl'
                placeholder='Короткое описание для генерации'
                value={description}
                onChange={handleOnDescriptionChange}
              />
            </InputLabel>
            
            <Stack direction='column' py={1} width='100%' mt={1}>
              <p className='fs13-secondary-thin'>Дизайн</p>
              <Stack direction='row' spacing={1}>
                <Button 
                  size='xl'
                  view={stickerType === StickerType.rectangle ? 'outlined-success' : 'outlined'}
                  onClick={() => handleOnStickerTypeChange(StickerType.rectangle)}
                >
                  Вертикальный
                </Button>
                <Button 
                  size='xl'
                  view={stickerType === StickerType.banner ? 'outlined-success' : 'outlined'}
                  onClick={() => handleOnStickerTypeChange(StickerType.banner)}
                >
                  Горизонтальный
                </Button>
                <Button 
                  size='xl'
                  view={stickerType === StickerType.other ? 'outlined-success' : 'outlined'}
                  onClick={() => handleOnStickerTypeChange(StickerType.other)}
                >
                  Другое
                </Button>
              </Stack>
              <StickerCarousel type={stickerType}>
                {stickersByType.map(sticker =>
                  <ImgWrapper 
                    key={sticker.key}
                    src={sticker.img} 
                    height={sticker.height} 
                    width={sticker.width}
                    select={currentSticker?.key === sticker.key}
                    onClick={() => setCurrentSticker(sticker)}
                  />
                )}
              </StickerCarousel>
            </Stack>

            <TextInput 
              size='xl'
              value={count}
              onChange={handleOnCountChange}
              placeholder='Введите количество'
              className={b('qr-count-input')}
              endContent={
                <Button 
                  className={b('qr-count-input-btn')}
                  view='action' 
                  size='xl'
                  disabled={disableGenBtn}
                  loading={generateTagRequestInfo.isLoading}
                  onClick={handleGenerateBtnClick}
                >
                  Сгенерировать
                  <QrCountBtnIcon />
                </Button>
              }
            />
            <Stack direction='row' spacing={1} mt={1}>
              {qrCountVariants.map(v =>
                <Label 
                  interactive
                  onClick={() => handleOnCountLabelClick(v)}
                >
                  {v}
                </Label>
              )}
            </Stack>
          </div>

          <Box 
            p={4}
            width='100%' 
            height='100%'
            borderRadius={4}
            bgcolor='#ffffff'
            flexGrow={1}
          >
            <h3 className='fs32-primary-bold'>
              Предпросмотр QR
            </h3>
            <Stack 
              justifyContent='center' 
              alignItems='center' 
              width='100%' 
              height='100%'
              py={4}
              px={3}
            >
              {currentSticker
                ? <ImgWrapper 
                    src={currentSticker.previewImg} 
                    height='auto'
                    width='auto'
                  />
                : null
              }
            </Stack>
          </Box>
        </Stack>
        
      </div>
    </Layout>
  );
};