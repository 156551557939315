export enum TagStatus {
  INACTIVE = 'INACTIVE',
  CREATED = 'CREATED',
  PRINTING = 'PRINTING',
  STORED = 'STORED',
  DEPOSITED = 'DEPOSITED',
  EXPIRED = 'EXPIRED',
}

export enum GenerationStatus {
  GENERATING = 'GENERATING',
  GENERATED = 'GENERATED',
  PRINTING = 'PRINTING',
  RECEIVED = 'RECEIVED',
}

export enum DesignType {
  BANNER_TYPE_A = "BANNER_TYPE_A",
  BANNER_TYPE_B = "BANNER_TYPE_B",
  BANNER_TYPE_C = "BANNER_TYPE_C",
  BANNER_TYPE_D = "BANNER_TYPE_D",
  BANNER_TYPE_E = "BANNER_TYPE_E",
  BANNER_TYPE_F = "BANNER_TYPE_F",
  BANNER_TYPE_G = "BANNER_TYPE_G",
  RECTANGLE_TYPE_A = "RECTANGLE_TYPE_A",
  RECTANGLE_TYPE_B = "RECTANGLE_TYPE_B",
  RECTANGLE_TYPE_C = "RECTANGLE_TYPE_C",
  RECTANGLE_TYPE_D = "RECTANGLE_TYPE_D",
  RECTANGLE_TYPE_E = "RECTANGLE_TYPE_E",
  RECTANGLE_TYPE_F = "RECTANGLE_TYPE_F",
  RECTANGLE_TYPE_G = "RECTANGLE_TYPE_G",
  ROUND_TYPE_A = "ROUND_TYPE_A",
  ROUND_TYPE_B = "ROUND_TYPE_B",
  SQUARE_TYPE_A = "SQUARE_TYPE_A",
  SQUARE_TYPE_B = "SQUARE_TYPE_B",
  SQUARE_TYPE_C = "SQUARE_TYPE_C"
}

export interface IGeneration {
  id: string,
  status: GenerationStatus,
  count: number,
  description: string,
  createdAt: string,
  ownerName: string,
  link: string | null,
}

export interface ITag {
  id: string,
  status: TagStatus,
  createdAt: string,
  createdBy: string,
  expiresAT: string | null,
}