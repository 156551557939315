import rectTypeA from '../../../assets/stickers/rect_type_a.png';
import rectTypeB from '../../../assets/stickers/rect_type_b.png';
import rectTypeC from '../../../assets/stickers/rect_type_c.png';
import rectTypeD from '../../../assets/stickers/rect_type_d.png';
import rectTypeE from '../../../assets/stickers/rect_type_e.png';
import rectTypeF from '../../../assets/stickers/rect_type_f.png';
import rectTypeG from '../../../assets/stickers/rect_type_g.png';
import rectTypeAPrev from '../../../assets/stickers/rect_type_a_prev.png';
import rectTypeBPrev from '../../../assets/stickers/rect_type_b_prev.png';
import rectTypeCPrev from '../../../assets/stickers/rect_type_c_prev.png';
import rectTypeDPrev from '../../../assets/stickers/rect_type_d_prev.png';
import rectTypeEPrev from '../../../assets/stickers/rect_type_e_prev.png';
import rectTypeFPrev from '../../../assets/stickers/rect_type_f_prev.png';
import rectTypeGPrev from '../../../assets/stickers/rect_type_g_prev.png';

import bannerTypeA from '../../../assets/stickers/banner_type_a.png';
import bannerTypeB from '../../../assets/stickers/banner_type_b.png';
import bannerTypeC from '../../../assets/stickers/banner_type_c.png';
import bannerTypeD from '../../../assets/stickers/banner_type_d.png';
import bannerTypeE from '../../../assets/stickers/banner_type_e.png';
import bannerTypeF from '../../../assets/stickers/banner_type_f.png';
import bannerTypeG from '../../../assets/stickers/banner_type_g.png';
import bannerTypeAPrev from '../../../assets/stickers/banner_type_a_prev.png';
import bannerTypeBPrev from '../../../assets/stickers/banner_type_b_prev.png';
import bannerTypeCPrev from '../../../assets/stickers/banner_type_c_prev.png';
import bannerTypeDPrev from '../../../assets/stickers/banner_type_d_prev.png';
import bannerTypeEPrev from '../../../assets/stickers/banner_type_e_prev.png';
import bannerTypeFPrev from '../../../assets/stickers/banner_type_f_prev.png';
import bannerTypeGPrev from '../../../assets/stickers/banner_type_g_prev.png';

import squareTypeA from '../../../assets/stickers/square_type_a.png';
import squareTypeB from '../../../assets/stickers/square_type_b.png';
import squareTypeC from '../../../assets/stickers/square_type_c.png';
import squareTypeAPrev from '../../../assets/stickers/square_type_a_prev.png';
import squareTypeBPrev from '../../../assets/stickers/square_type_b_prev.png';
import squareTypeCPrev from '../../../assets/stickers/square_type_c_prev.png';

import roundTypeA from '../../../assets/stickers/round_type_a.png';
import roundTypeB from '../../../assets/stickers/round_type_b.png';
import roundTypeAPrev from '../../../assets/stickers/round_type_a_prev.png';
import roundTypeBPrev from '../../../assets/stickers/round_type_b_prev.png';
import { DesignType } from '../../../store/api/admin/types/models';
import Carousel from 'react-multi-carousel';
import React from 'react';
import { Box } from '@mui/material';


const generateData = (type: string, img: string, key: string, previewImg: string, width: number, height: number): ISticker => ({
  type, 
  img, 
  key,
  previewImg,
  width,
  height
} as ISticker)

export enum StickerType {
  rectangle = 'rectangle',
  banner = 'banner',
  other = 'other',
}

export interface ISticker {
  type: StickerType,
  img: string,
  key: DesignType,
  previewImg: string,
  width: number, 
  height: number
}

export const stickers: ISticker[] = [
  generateData(StickerType.rectangle, rectTypeA, DesignType.RECTANGLE_TYPE_A, rectTypeAPrev, 142, 85),
  generateData(StickerType.rectangle, rectTypeB, DesignType.RECTANGLE_TYPE_B, rectTypeBPrev, 142, 85),
  generateData(StickerType.rectangle, rectTypeC, DesignType.RECTANGLE_TYPE_C, rectTypeCPrev, 142, 85),
  generateData(StickerType.rectangle, rectTypeD, DesignType.RECTANGLE_TYPE_D, rectTypeDPrev, 142, 85),
  generateData(StickerType.rectangle, rectTypeE, DesignType.RECTANGLE_TYPE_E, rectTypeEPrev, 142, 85),
  generateData(StickerType.rectangle, rectTypeF, DesignType.RECTANGLE_TYPE_F, rectTypeFPrev, 142, 85),
  generateData(StickerType.rectangle, rectTypeG, DesignType.RECTANGLE_TYPE_G, rectTypeGPrev, 142, 85),

  generateData(StickerType.banner, bannerTypeA, DesignType.BANNER_TYPE_A, bannerTypeAPrev, 70, 117),
  generateData(StickerType.banner, bannerTypeB, DesignType.BANNER_TYPE_B, bannerTypeBPrev, 70, 117),
  generateData(StickerType.banner, bannerTypeC, DesignType.BANNER_TYPE_C, bannerTypeCPrev, 70, 117),
  generateData(StickerType.banner, bannerTypeD, DesignType.BANNER_TYPE_D, bannerTypeDPrev, 70, 117),
  generateData(StickerType.banner, bannerTypeE, DesignType.BANNER_TYPE_E, bannerTypeEPrev, 70, 117),
  generateData(StickerType.banner, bannerTypeF, DesignType.BANNER_TYPE_F, bannerTypeFPrev, 70, 117),
  generateData(StickerType.banner, bannerTypeG, DesignType.BANNER_TYPE_G, bannerTypeGPrev, 70, 117),

  generateData(StickerType.other, squareTypeA, DesignType.SQUARE_TYPE_A, squareTypeAPrev, 85, 85),
  generateData(StickerType.other, squareTypeB, DesignType.SQUARE_TYPE_B, squareTypeBPrev, 85, 85),
  generateData(StickerType.other, squareTypeC, DesignType.SQUARE_TYPE_C, squareTypeCPrev, 85, 85),
  generateData(StickerType.other, roundTypeA, DesignType.ROUND_TYPE_A, roundTypeAPrev, 85, 85),
  generateData(StickerType.other, roundTypeB, DesignType.ROUND_TYPE_B, roundTypeBPrev, 85, 85),
];

export const carouselProps = {
  
  responsive: {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  }
};


interface StickerCarouselProps {
  children: React.ReactNode,
  type: StickerType | string
}

export const StickerCarousel: React.FC<StickerCarouselProps> = ({
  children,
  type
}) => {

  const [render, setRender] = React.useState(false);

  React.useEffect(() => {
    setRender(true);
    setTimeout(() => setRender(false), 100);
  }, [type])

  return (
    <>
      {!render
        ? <Carousel 
            infinite={false}
            autoPlay={false}
            responsive={{
              desktop: {
                breakpoint: { max: 3000, min: 300 },
                items: type === StickerType.rectangle ? 3.5 : type === StickerType.other ? 5 : 7
              }
            }} 
          >
            {children}
          </Carousel>
        : <Box height={134} minHeight={134} />
      }
    </>
  )
}